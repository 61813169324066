.ticket-page-container{
    width: 100%;
    background-color: black;
    padding: 50px 0;
    /* overflow-x: hidden; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 130px;
}
.ticket-page-div{
    width: 340px;
    height: 750px;
    background-color: white;
    box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.5);
    border-radius: 34px;
}
.ticket-page-img{
    width: 340px;
    height: 350px;
    /* border: 2px solid black;
    border-radius: 20px; */
}
.ticket-page-img img{
    width: 100%;
    height: 100%;
    border-radius: 31px;
}
.ticket-content{
    padding: 15px 20px;
}
.ticket-header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
}
.ticket-header .content h4{
    font-size: 22px;
}
.ticket-header::after{
    content: " ";
    position: absolute;
    width: 339.9px;
    background-color: lightgrey;
    height: 2px;
    left: -6.5%;
    bottom: -30%;
}
.ticket-header .content p{
    font-size: 10px;
    font-weight: lighter;
}
.ticket-header .icon{
    width: 50px;
    height: 50px;
    border: 1px solid lightgray;
    text-align: center;
    border-radius: 50px;
}
.icon:active{
    border: 1px solid lightgray;
    opacity: 0.6;
}

.ticket-body{
    margin-top: 30px;
    width: 100%;
    height: 100%;
    position: relative;
}
.content-des{
    display: flex;
    flex-wrap: wrap;
}
.info-des{
    width: 150px;
    margin-top: 10px;
}

.info-des span{
    color: lightgray;
    font-size: 13px;
}
.info-des p{
    font-size: 13px;
    margin-top: 5px;
}

/* .ticket-body::after{
    content: " ";
    position: absolute;
    width: 339.9px;
    background-color: lightgrey;
    height: 2px;
    left: -6.5%;
    bottom: -13%;
} */
.ticket-number{
    margin: 50px 0;
    position: absolute;
    width: 50%;
    left: 33%;
}
.ticket-number span{
    color: lightgray;
    font-size: 13px;
}

.ticket-number p{
    font-size: 13px;
    margin-top: 5px;
    margin-left: 30px;
}
@media screen and (max-width:320px){
    .ticket-page-div {
        width: 300px;
        height: 750px;
        background-color: white;
        box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.5);
        border-radius: 34px;
    }
    .ticket-page-img {
        width: 300px;
        height: 350px;
    }
    .ticket-header::after{
        content: " ";
        position: absolute;
        width: 299px;
        background-color: lightgrey;
        height: 2px;
        left: -7.5%;
        bottom: -30%;
    }
    element.style {
        height: 100%;
    }
    .info-des {
        width: 120px;
        margin-top: 10px;
    }
 
}