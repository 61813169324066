.btn-container{
    width: 320px;
}

.btn-container .button{
    width: 100%;
    border-radius: 10px;
    color: #ffff;
    background-color: #ff5531;
    border: none;
    font-size: 13px;
    padding: 15px 10px;
    font-weight: bold;
}
.button:active{
    opacity: 0.5;
}

