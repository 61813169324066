.detail-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
    padding-top: 10px;

}
.detail-container .img-container{
    width: 320px;
    height: 330px;
    /* border-radius: 20px;
    border: 1px solid black; */
    margin-bottom: 20px;
    /* padding-left: 20px;
    padding-right: 20px; */
}
.img-container img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.detail-container-middle{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.detail-container-middle h3{
    margin-bottom: 5px;

}
.info-div{
    margin-bottom: 15px;
}
.about{
    margin-top: 10px;
    margin-bottom: 15px;
}
.about h3{
    margin-bottom: 10px;
}
.about p{
    width: 320px;
    font-size: 15px;
}
.button-show{
    margin: 30px 0;
}
.info-div .info{
    margin-top: 13px;
}
.info-div .info p{
    font-size: 18px;
    width: 320px;
}

@media screen and (max-width:320px){
    .detail-container .img-container {
        width: 300px;
        height: 330px;
        margin-bottom: 20px;
    }
    .info-div {
        padding: 15px;
    }
}