.order-container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    overflow: hidden;
    padding-top: 30px;
}
.order-container h2{
    text-align: left;
    width: 100%;
    margin-left: 40px;
}

.input-container{
    width: 100%;
    height: 100%;
}
.summary-div{
    margin: 15px auto;
    width: 330px;
    height: 180px;
    box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 15px 20px;
}
.summary-div .quan{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 25px;
}
.quan p{
    font-size: 20px;
}
.ticket-button{
    margin: 0 auto;
    width: 270px;
}
.total{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 49px;
    position: relative;
}
.total p:first-child{
    font-weight: bold;
    font-size: 24px;
}
.total p:last-child{
    font-size: 17px;
}
.total::after{
    content: " ";
    position: absolute;
    width: 325px;
    border: 1px solid lightgray;
    top: -60%;
}